
const DepositionCard = (props) => {
  return (
    <>
      <div className="depoimentos_services">
        <div>
          <img src={props.img} width="50" height="50" alt="Web Design" />
          <p className="depoimentos_text">{props.nome}</p>
          <p className="depoimentos_description">
          {props.text}
          </p>
        </div>
      </div>
      
    </>
  )
}

export default DepositionCard

//Luciana A.
//“Quando colegas de segmento me indicaram a Ardósia MR confesso que achava a propaganda boa demais para ser verdade, mas vi que é tudo isso que me falavam mais um pouco, eles realmente me tratam como parceira de mercado e isso é incrivel”

//Stefano G.
//“Cada vez consigo pegar projetos maiores, tenho uma vida antes e uma outra vida após conhecer a Ardósia MR”

//Amadeu F.
//“Eles me tratam como se fosse um grande parceiro mesmo sendo um cliente “pequeno”, até hoje isso me intriga”

//Ana Carolina
//“Graças a Ardósia MR posso deixar minha criatividade alçar vôos que sempre quis, mas por experiências ruins com outras empresas já achava algo distante”

//Bernardo S.
//“Mesmo estando em outro estado eles conseguem me entregar mais rapidamente que empresas aqui de São Paulo”
