import { useState } from "react"
import { util } from "../util/util"
import { fb } from "../firebase/Firebase"
import PrivacyModal from "../components/PrivacyModal"

const FormContact = () => {

  const [nome, setNome] = useState("")
  const [email, setEmail] = useState("")
  const [telefone, setTelefone] = useState("")
  const [cidade, setCidade] = useState("")
  const [estado, setEstado] = useState("")
  const [produtos, setProdutos] = useState("")
  const [detalhes, setDetalhes] = useState("")
  const [novidades, setNovidades] = useState(false)

  const [nomeClass, setNomeClass] = useState("")
  const [emailClass, setEmailClass] = useState("")
  const [telefoneClass, setTelefoneClass] = useState("")
  const [cidadeClass, setCidadeClass] = useState("")
  const [estadoClass, setEstadoClass] = useState("")
  const [produtosClass, setProdutosClass] = useState("")
  const [detalhesClass, setDetalhesClass] = useState("")

  const [send, setSend] = useState(false)

  const [privacy, setPrivacy] = useState(false)

  const getValues = (ev) => {
    const { name, value } = ev.target

    switch (name) {
      case "nome":
        setNome(value)
        value.length ? setNomeClass("input-success") : setNomeClass("")
        break
      case "email":
        setEmail(value)
        util.validEmail(value) ? setEmailClass("input-success") : setEmailClass("")
        break
      case "telefone":
        document.getElementById("telefone").attributes[0].ownerElement["value"] = util.maskPhone(value)
        setTelefone(value)
        value.length >= 14 ? setTelefoneClass("input-success") : setTelefoneClass("")
        break
      case "cidade":
        setCidade(value)
        value.length ? setCidadeClass("input-success") : setCidadeClass("")
        break
      case "estado":
        setEstado(value)
        value.length ? setEstadoClass("input-success") : setEstadoClass("")
        break
      case "produtos":
        setProdutos(value)
        value.length ? setProdutosClass("input-success") : setProdutosClass("")
        break
      case "detalhes":
        setDetalhes(value)
        value.length ? setDetalhesClass("input-success") : setDetalhesClass("")
        break
      default:
        break
    }
  }

  const sendForm = () => {
    if (validForm()) {
      let formValues = {
        nome: nome,
        email: email,
        telefone: telefone,
        estado: estado,
        cidade: cidade,
        produtos:produtos,
        detalhes:detalhes,
        novidades:novidades
      }
      fb.registro.add(formValues).then(() => {
        sendEmail(formValues)
      }).catch(error => { console.log(error) })
    }
  }

  const validForm = () => {
    let validation = true

    if (!nome.length) {
      setNomeClass("input-danger")
      validation = false
    }
    if (!util.validEmail(email)) {
      setEmailClass("input-danger")
      validation = false
    }
    if (telefone.length < 14) {
      setTelefoneClass("input-danger")
      validation = false
    }
    if (!cidade.length) {
      setCidadeClass("input-danger")
      validation = false
    }
    if (!estado.length) {
      setEstadoClass("input-danger")
      validation = false
    }

    return validation
  }

  const sendEmail = (data) => {
    fetch("https://formsubmit.co/ajax/vendas@ardosiamr.com.br", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        Cliente: data.nome,
        Email: data.email,
        Telefone: data.telefone,
        Mensagem: 
        `Cidade: ${data.cidade} - ${data.estado}  \n` +
        `Produto: ${data.produtos}  \n` +
        `Detalhes: ${data.detalhes}  \n` +
        `Novidades: ${data.novidades? "Autorizadas" :  "Não autorizadas"}`

            
      }),
    })
    .then((response) => {
      response.json()
    })
    .then((sendData) => {
      console.log("Enviado!")
    })
    .catch((error) => console.log(error));
    resetForm()
  }

  const resetForm = () => {
    setNome("")
    setEmail("")
    setTelefone("")
    setCidade("")
    setEstado("")
    setProdutos("")
    setDetalhes("")
    setNovidades(false)

    setNomeClass("")
    setEmailClass("")
    setTelefoneClass("")
    setCidadeClass("")
    setEstadoClass("")
    setProdutosClass("")
    setDetalhesClass("")

    setSend(true)

    document.querySelector("form").reset();
  }

  return (
    <>
      <section aria-label="Formulario" className="formulario" id="formulario">
        <div className="formulario_container container">
          <div className="formulario_data">
            <p className="formulario_text primary-color">ORÇAMENTO</p>
            <h1 className="formulario_title">Dúvidas? Fale com um especialista!</h1>
            <p className="formulario_description">Preencha o formulário e envie seu pedido de orçamento. Um de nossos especialistas irá entrar em contato com você.
</p>
            <div className="detals">
              <div className="formulario_description2">
                <h2>Orce sem nenhum <br className="form-br" />compromisso</h2>
                <p>Nosso objetivo é entender suas necessidades e lhe transmitir confiança, culminando assim em uma parceria de sucesso.</p>
              </div>
              <div className="formulario_description2">
                <h2>Entregamos em<br className="form-br" /> todo o brasil</h2>
                <p>Estamos mais do que preparados para lhe atender, independente da sua localidade. </p>
              </div>
            </div>
          </div>
          <div className="formulario_content">
            <form action="https://formsubmit.co/vendas@ardosiamr.com.br">
              <div className="user-details">
                <div className="input-box">
                  <div className={nomeClass}>
                    <input type="text" placeholder="Seu nome*" name="nome" onChange={getValues} />
                  </div>
                </div>
                <div className="input-box">
                  <div className={emailClass}>
                    <input type="text" placeholder="E-mail*" name="email" onChange={getValues} />
                  </div>
                </div>
                <div className="input-box">
                  <div className={telefoneClass}>
                    <input id="telefone" type="text" placeholder="Telefone*" name="telefone" onChange={getValues} />
                  </div>
                </div>
                <div className="input-box-1">
                  <div className="select-box">
                    <div className={estadoClass}>
                      <select name="estado" className="minimal" onChange={getValues}>
                        <option value="">Estado*</option>
                        <option value="AC">AC</option>
                        <option value="AL">AL</option>
                        <option value="AP">AP</option>
                        <option value="AM">AM</option>
                        <option value="BA">BA</option>
                        <option value="CE">CE</option>
                        <option value="DF">DF</option>
                        <option value="ES">ES</option>
                        <option value="GO">GO</option>
                        <option value="MA">MA</option>
                        <option value="MT">MT</option>
                        <option value="MS">MS</option>
                        <option value="MG">MG</option>
                        <option value="PA">PA</option>
                        <option value="PB">PB</option>
                        <option value="PR">PR</option>
                        <option value="PE">PE</option>
                        <option value="PI">PI</option>
                        <option value="RJ">RJ</option>
                        <option value="RN">RN</option>
                        <option value="RS">RS</option>
                        <option value="RO">RO</option>
                        <option value="RR">RR</option>
                        <option value="SC">SC</option>
                        <option value="SP">SP</option>
                        <option value="SE">SE</option>
                        <option value="TO">TO</option>
                      </select>
                    </div>
                  </div>

                  <div className="input-box-2">
                    <div className={cidadeClass}>
                      <input type="text" placeholder="Cidade*" name="cidade" onChange={getValues} />
                    </div>
                  </div>

                </div>
                <div className="input-box">
                  <div className={produtosClass}>
                    <input type="text" placeholder="Quais produtos" name="produtos" onChange={getValues} />
                  </div>
                </div>
                <div className="input-box">
                  <div className={detalhesClass}>
                    <input type="text" placeholder="Detalhes do Orçamento" name="detalhes" onChange={getValues} />
                  </div>
                </div>
              </div>
              <div className="gender-details">
                <input type="checkbox" name="gender" id="dot" value={novidades} />
                <div className="category">
                  <label>
                    <span className={["dot one", novidades? "active" : ""].join(" ")} onClick={() => {novidades? setNovidades(false) : setNovidades(true)}}></span>
                    <span className="gender">Eu concordo em receber comunicações e novidades da MR.
                    </span>
                  </label>
                </div>
                <span className="mb-1">
                  Ao informar meus dados, eu concordo com a
                  <PrivacyModal/>
                </span>
              </div>
              <div>
                <button className="botao mt-1 mb-1" type="button" onClick={sendForm}>
                  Enviar
                </button>
              </div>
              <small className="text-success">{send? "Solicitação de orçamento enviada com sucesso!" : ""}</small>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default FormContact