import { useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import { categorias } from '../data/Data';

const MenuToggle = () => {

  const location = useLocation()

  const [filterProds, setFilterProds] = useState([])
  const [search, setSearch] = useState("")

  const closeMenu = () => {
    let menuMobile = document.getElementById("menuToggle")
    let menuBtn = document.getElementById("menuBtn")

    menuMobile.classList.remove("active");
    menuBtn.classList.remove("active");

  }

  useEffect(() => {
    setFilterProds(categorias.filter(obj => obj.id.toLocaleLowerCase().includes(search.toLocaleLowerCase())))
  }, [search])

  useEffect(() => {
    closeMenu()
  }, [location])

  return (
    <>
      <section className="menu-mobile" id="menuToggle">
        <div className="search-bar">
          <div className="search-box active">
            <input
              className="search-txt"
              type="text"
              placeholder="Pesquise..."
              name="search"
              onChange={(e) => { setSearch(e.target.value) }}
              value={search}
            />
            <div className="search-btn">
              <div className="search-icon">
              </div>
            </div>
            <ul className={["search-list", search.length? "active" : ""].join(" ")}>
              {
                filterProds.map((obj) => {
                  return (
                    search.length ?
                      <Link className="search-link" key={obj.id} onClick={() => { setSearch("") }} to={`produtos/${obj.id}/default`}><li>{obj.nome}</li></Link>
                      : ""
                  )
                })
              }
            </ul>
          </div>
        </div>
        <nav className="nav-mobile">
          <ul className="nav_list">
            <li className="nav_item">
              <Link to={"/"} onClick={closeMenu} className="nav_link">Home</Link>
            </li>
            <li className="nav_item">
              <Link to={"/sobre"} onClick={closeMenu} className="nav_link">Sobre nós</Link>
            </li>
            <li className="nav_item">
              <Link to={"/produtos"} onClick={closeMenu} className="nav_link">Produtos</Link>
            </li>
            <li className="nav_item">
              <Link to={"/orcamento"} onClick={closeMenu} className="nav_link">Orçamento</Link>
            </li>
            <li className="nav_item">
              <Link to={"/blog"} onClick={closeMenu} className="nav_link">Blog</Link>
            </li>
          </ul>
        </nav>
        <div className="menu-social">
          <h5>Social Media</h5>
          <div className="social">
            <a className="social-brand" href={"https://www.facebook.com/ardosiamr"} target={"_blank"} rel={"noreferrer"}>
              <img src="assets/font-awesome/icons/Facebook.svg" alt="Face Brand" />
            </a>
            <a className="social-brand" href={"https://www.instagram.com/ardosiamr/"} target={"_blank"} rel={"noreferrer"}>
              <img src="assets/font-awesome/icons/instagram.png" height={"25px"} alt="Instagram Brand" />
            </a>
            <a className="social-brand" href={"https://wa.me/5531999751440"} target={"_blank"} rel={"noreferrer"}>
              <img src="assets/font-awesome/icons/whatsapp.png" height={"25px"} alt="Wpp Brand" />
            </a>
          </div>
        </div>
      </section>
    </>
  )
}

export default MenuToggle