import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from "swiper";

import { depositions } from '../data/Data';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import DepositionCard from "../components/DepositionCard"

const Depositions = () => {
  return (
    <>
      <Swiper
        modules={[Autoplay, Navigation, Pagination]}
        loop={true}
        grabCursor={true}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          855: {
            slidesPerView: 2,
          },
          1250: {
            slidesPerView: 3,
          },
        }}
        className="mySwiper">
        {
          depositions.map((obj) => {
            return (
              <SwiperSlide key={obj.nome}>
                <DepositionCard img={obj.img} nome={obj.nome} text={obj.text} />
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </>
  )
}

export default Depositions