import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Keyboard, Pagination } from "swiper";
import { maisVendidos } from '../data/Data';

import ProductCard from "../components/ProductCard"

// Import Swiper styles
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";


const MoreProducts = () => {

  let aux = 0;
  const moreProds = maisVendidos.map((obj) => {
    aux++
    return(
      <SwiperSlide key={aux}>
            <ProductCard id={obj.id} nome={obj.nome} img={obj.img} cor={obj.cor}/>
      </SwiperSlide>
    )
  })

  return (
    <>
      <div className="more-products">
        <div className="title-block">
          <h2>Pedras mais vendidas</h2>
        </div>
        <Swiper 
        navigation={true} 
        modules={[Navigation, Keyboard, Pagination]} 
        loop={true} 
        /* grabCursor={true}  */
        keyboard={{
          enabled: true,
        }} 
        pagination={{
          clickable: true,
        }} 
        breakpoints={{
          500: {
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
          640: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          960: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          }
        }} 
        className="mySwiper">
          {moreProds}
        </Swiper>
      </div>
    </>
  )
}

export default MoreProducts