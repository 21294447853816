import { Link, useNavigate } from "react-router-dom"
import { homeProducts } from "../data/Data"
import Depositions from "../components/Depositions"
import FormContact from "../components/FormContact"
import ProductCard from "../components/ProductCard"
import { useState } from "react"

const Home = () => {

  const navigate = useNavigate()

  const [prodSlider, setProdSlider] = useState(homeProducts.prods01)

  const selecSlider = (value) => {
    let selectArray = ["prods01", "prods02", "prods03", "prods04", "prods05"]
    selectArray.forEach((sec) => {
      let selected = document.getElementById(sec)
      if (selected.classList.contains("active") && value !== sec) {
        selected.classList.remove("active")
      } else {
        if (value === sec) {
          selected.classList.add("active")
        }
      }
    })

    if (value === "prods01") {
      setProdSlider(homeProducts.prods01)
    } else if (value === "prods02") {
      setProdSlider(homeProducts.prods02)
    } else if (value === "prods03") {
      setProdSlider(homeProducts.prods03)
    } else if (value === "prods04") {
      setProdSlider(homeProducts.prods04)
    } else if (value === "prods05") {
      navigate("/produtos")
    }
  }

  const productsGrid = prodSlider.map((obj) => {
    return (
      <ProductCard key={obj.cor} id={obj.id} nome={obj.nome} img={obj.img} cor={obj.cor} />
    )
  })

  return (
    <>
      <main className="main">
        {/*
                <!--============ HOME ============--> */}
        <section aria-label="home" className="home" id="home">
          <div className="home_container container grid">
            <div className="home_data">
              <p className="home_text primary-color">Ardósia</p>
              <h1 className="home_title">
                Seja bem vindo <br />
                a Ardósia MR
              </h1>
              <p className="home_description">
                Torne-se mais um de nossos parceiros de mercado,
                tendo um atendimento personalizado, que cumpre os prazos
                e ainda assim com um preço justo.

              </p>
              <Link to="/orcamento" className="botao mt-2">Fazer Orçamento</Link>
            </div>
            <img src="assets/img/photos/img principal.png" alt="foto principal" width="" height=""
              className="home_img" />
          </div>
        </section>
        {/*
                <!--====================SOBRE==============================--> */}
        <section aria-label="sobre" className="sobre" id="sobre">
          <div className="sobre_container container grid">
            <div className="sobre_data">
              <p className="sobre_text primary-color">SOBRE</p>
              <h1 className="sobre_title">
                30 anos criando e
                <br /> inovando o mercado!
              </h1>
              <p className="sobre_description">
                Tendo um líder que já esteve na empresa também como um colaborador, fez com que adquirisse uma visão integral das oportunidades e desafios do mercado.

              </p>
              <p className="sobre_description">Culminando no domínio dos processos e demandas necessárias para entregar um trabalho de excelência para seus atuais parceiros de mercado.
              </p>
              <p className="sobre_description">
                Concretizando assim histórias de sucesso com seus clientes, aliando uma localização privilegiada, entre Curvelo (centro de Minas Gerais), Belo Horizonte (capital mineira) e Confins onde situa-se o aeroporto internacional, o escoamento de produtos se tornou mais facilitado.
              </p>
              <p className="sobre_description">Além de benefícios geográficos, a Ardósia MR conta com uma expertise de mais de 30 anos no mercado, estando também sempre atualizada quando o assunto são tecnologias de ponta e maquinário industrial, sendo possível o atendimento de altas demandas com um alto padrão de eficiência.
              </p>
              <p className="sobre_description">Em virtude do fundador da empresa já ter tido a experiência de ser colaborador da antiga firma onde atualmente se encontra a Ardósia MR, ocorreu de que ele obtivesse conhecimento das reais necessidades de seus parceiros.</p>
              <p className="sobre_description">Elaborando um modelo de atendimento que acompanha o parceiro desde o projeto até a conclusão da obra, colocando a Ardósia MR como uma importante aliada contra todo tipo de imprevistos, pois na Ardósia MR o trabalho só termina com a satisfação do parceiro de mercado.</p>

              <div>
                <div>
                  <Link className="botao3 seta primary-color center" to="/sobre" >Saiba mais
                  </Link>
                </div>
              </div>
            </div>
            <div className="sobre-video">
              <iframe src="https://www.youtube.com/embed/NJBBpw292-c" title="YouTube video player" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            </div>
          </div>
        </section>
        {/* <!--=================SERVICOS========================--> */}
        <section aria-label="services" className="services container" id="services">
          <div className="services_container">
            <div className="services_services">
              <div className="services_div">
                <div className="block-img01"></div>
                <p className="services_text">Objetivo</p>
                <p className="services_description">
                  Não temos apenas clientes, temos parceiros de mercado, aliados inclusive nas possíveis dificuldades do seu projeto.
                </p>
              </div>
            </div>
            <div className="services_services services_development">
              <div className="services_div">
                <div className="block-img02"></div>
                <p className="services_text">Diferenciais</p>
                <p className="services_description">
                  Suas metas são nossa prioridade, para que nossos parceiros tenham foco na entrega de produtos de boa qualidade e ainda assim com preço competitivo.
                </p>
              </div>
            </div>
            <div className="services_services services_development">
              <div className="services_div">
                <div className="block-img03"></div>
                <p className="services_text">Profissionais</p>
                <p className="services_description">
                  A Ardósia MR certifica-se de sempre ter os profissionais mais capacitados e comprometidos com nossos parceiros.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/*
                <!--=================Depoimentos====================--> */}
        <section aria-label="depoimentos" className="depoimentos container" id="depoimentos">
          <div className="depoimentos_content">
            <p className="depoimentos_text1 primary-color">DEPOIMENTOS</p>
            <h1 className="depoimentos_title">
              O que dizem sobre nós?
            </h1>
          </div>
          <Depositions />
        </section>
        {/*
                <!--================Produtos================--> */}
        <section aria-label="Produtos" className="produtos container mt-3" id="produtos">
          <div>
            <p className="produtos_categoria1 primary-color">O QUE ENTREGAMOS</p>
            <h1 className="produtos_title">
              Nossos Produtos
            </h1>
          </div>
          <div className="container">
            <input className="active" type="radio" name="s" id="prods01" />
            <input type="radio" name="s" id="prods02" />
            <input type="radio" name="s" id="prods03" />
            <input type="radio" name="s" id="prods04" />
            <input type="radio" name="s" id="prods05" />
            <nav className="navb">
              <div className="slider"></div>
              <label className="home" onClick={() => { selecSlider("prods01") }}>
                <i className="fas fa-home"></i>Ardósia
              </label>
              <label className="blog" onClick={() => { selecSlider("prods02") }}>
                <i className="fas fa-blog"></i>Granito
              </label>
              <label className="code" onClick={() => { selecSlider("prods03") }}>
                <i className="fas fa-code"></i>São Tomé
              </label>
              <label className="help" onClick={() => { selecSlider("prods04") }}>
                <i className="fas fa-envelope"></i>Portuguesa
              </label>
              <label className="about" onClick={() => { selecSlider("prods05") }}>
                <i className="fas fa-user"></i>Outros
              </label>
            </nav>
          </div>
          <div className="produtos_container">
            {productsGrid}
          </div>
          <div className="center">
            <Link className="botao3 seta primary-color" to="/produtos" >Ver mais</Link>
          </div>
        </section>


        {/*
        
                <!--===========Formulario========================--> */}
        <FormContact />
        {/*
                <!--=================Blog====================--> */}
        <section aria-label="blog" className="blog container grid" id="produtos">
          <div>
            <p className="blog_categoria1 primary-color">DICAS</p>
            <h1 className="blog_title">
              Conheça nosso Blog
            </h1>
          </div>
          <div className="blog_container">
            <div className="blog_services blog_development">
              <div className="blog_div">
                <img src="assets/img/photos/Rectangle1.png" width="100%" height="40" alt="Web Development" />
                <p className="blog_categoria">CATEGORIA</p>
                <p className="blog_nome">
                  Em construção
                </p>
                <p className="blog-description">
                  Nosso blog está em costrução, fique ligado para mais noticias!
                </p>
                <a className="botaoblog primary-color seta" href="/blog" >Ler mais </a>
              </div>
            </div>
            <div className="blog_services blog_development">
              <div className="blog_div">
                <img src="assets/img/photos/Rectangle.png" width="100%" height="40" alt="Web Development" />
                <p className="blog_categoria">CATEGORIA</p>
                <p className="blog_nome">
                  Em construção
                </p>
                <p className="blog-description">
                  Nosso blog esta em costrução, fique ligado para mais noticias!
                </p>
                <a className="botaoblog primary-color seta" href="/blog" >Ler mais </a>
              </div>
            </div>
            <div className="blog_services blog_development">
              <div className="blog_div">
                <img src="assets/img/photos/Rectangle1.png" width="100%" height="40" alt="Web Development" />
                <p className="blog_categoria">CATEGORIA</p>
                <p className="blog_nome">
                  Em construção
                </p>
                <p className="blog-description">
                  Nosso blog esta em costrução, fique ligado para mais noticias!
                </p>
                <a className="botaoblog primary-color seta" href="/blog" >Ler mais </a>
              </div>
            </div>
          </div>
        </section>
        {/*
                <!--============== Contato ======================--> */}
        <section aria-label="contato" className="contato" id="contato">
          <div className="contato_container container map-home">
            <div className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3765.9218964001852!2d-44.40483988509589!3d-19.28576242357876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa7b516ae542801%3A0xe93dee572591307!2sArdosia%20MR!5e0!3m2!1spt-BR!2sbr!4v1660357812464!5m2!1spt-BR!2sbr"
                title="ardosia" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
              <div className="adress-info">
                <div className="adress">
                  <h3>ENDEREÇO</h3>
                  <a href="/" className="cor-p10">Rua Francisco Carlos Ribeiro,<br /> 632 - Dom Cirilo</a>
                </div>
                <div className="contact">
                  <div className="email">
                    <h3>EMAIL</h3>
                    <a href={"mailto:vendas@ardosiamr.com.br"}
                      className="primary-color">vendas@ardosiamr.com.br</a>
                  </div>
                  <div className="phone-number">
                    <h3>TELEFONE</h3>
                    <a href="tel:03195878210" className="cor-p10">(31) 3714-1260</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default Home