import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";

const PhotosGallery = (props) => {

  let key = 0;

  const listPhotos = props.listPhotos.map((src) => {
    key++
    return (
      <SwiperSlide key={key}>
        <div className="photos-block">
          <img src={src} alt="Foto Slider" />
        </div>
      </SwiperSlide>
    )
  })

  return (
    <>
      <div className="photos-slider container">
        {props.children}
        <Swiper
          effect={"fade"}
          navigation={true}
          modules={[EffectFade, Navigation]}
          className="mySwiper"
        >
          {listPhotos}
        </Swiper>
      </div>
    </>
  )
}


export default PhotosGallery