import Depositions from "../components/Depositions"
import FormContact from "../components/FormContact"

const Orcamento = () => {

  return (
    <>
      <section aria-label="orcamento" className="orcamento" id="sobre">
        <div className="container orcamento_baner">
          <img src="assets/img/photos/orcamento.png" alt="" />
        </div>
        <div className="orcamento_container container">
          <div className="orcamento_data">
            <p className="orcamento_text primary-color">SOBRE</p>
            <h1 className="orcamento_title">
              Por que comprar <br />
              com a Ardosia MR:
            </h1>
            <p className="orcamento_description">
            Entre ser um mero cliente ou ter uma parceria de mercado, sabemos qual a decisão mais lucrativa e duradoura…

            </p>
            <div>
              <ul>
                <li className="topicos_container">
                  <div className="block-img01"></div>
                  {/* <img src="assets/font-awesome/icons/seta_branca.svg" alt="algo" width={"60px"} height={"60px"} /> */}
                  <div className="topicos_text">
                    <h4>Entrega no prazo</h4>
                    <p>Sempre cumpra suas metas e seja reconhecido por sua pontualidade na entrega de seus projetos
</p>
                  </div>
                </li>
                <li className="topicos_container">
                  <div className="block-img01"></div>
                  {/* <img src="assets/font-awesome/icons/seta_branca.svg" alt="algo" width={"60px"} height={"60px"} /> */}
                  <div className="topicos_text">
                    <h4>Qualidade que encanta</h4>
                    <p>Seja um profissional que desperta admiração nos seus clientes pelos produtos que fornece</p>
                  </div>
                </li>
                <li className="topicos_container">
                  <div className="block-img01"></div>
                  {/* <img src="assets/font-awesome/icons/seta_branca.svg" alt="algo" width={"60px"} height={"60px"} /> */}
                  <div className="topicos_text">
                    <h4>Parceria de mercado</h4>
                    <p>Seja qualquer imprevisto sempre estaremos prontos para te atender da melhor forma e com o menor tempo possível </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="orcamento_mosaico">
            <img src="assets/img/photos/img principal.png" alt="foto principal" width="" height="" className="orcamento_img" />
          </div>
        </div>
      </section>

      {/*<!--=====================Formulario===================================--> */}

      <FormContact />

      {/*
      <!--=================Depoimentos====================--> */}

      <section aria-label="depoimentos" className="depoimentos container" id="depoimentos">
        <div>
          <p className="depoimentos_text1 primary-color">DEPOIMENTOS</p>
          <h1 className="depoimentos_title">
            O que dizem sobre nós?
          </h1>
        </div>
        <Depositions />
      </section>
    </>
  )
}

export default Orcamento;