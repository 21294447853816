import { Link } from "react-router-dom"

const ProductCard = (props) => {
  return (
    <>
      <div className="product-card">
        <Link to={`/produtos/${props.id}/${props.cor}`} >
          <div className="product-image">
            <img src={props.img} alt="Imagem Produto" />
          </div>
          <div className="product-info">
            <div className="info-block">
              <div className="info-text">
                <p>{props.nome}</p>
                <h3>{props.cor}</h3>
              </div>
            </div>
          </div> 
        </Link>
      </div>
    </>
  )
}

export default ProductCard