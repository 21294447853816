import { Link } from "react-router-dom";

const Footer = () => {

  return (
    <>
      <footer className="footercontainer container">
        <div className="containerfooter">
          <div className="image">
            <div className="footer-logo">

              {/* <img src={"assets/img/logo.png"} alt="" /> */}
            </div>
            <p>Torne-se mais um de nossos parceiros de mercado, tendo um atendimento personalizado, que cumpre os prazos e ainda assim com um preço justo.</p>
          </div>
          <div className="navbar-footer">
            <h5>Produtos</h5>
            <ul>
              <li>
                <Link to={"/produtos/ardosia/default"}>
                  Ardósia
                </Link>
              </li>
              <li>
                <Link to={"/produtos/granito/default"}>
                  Granito
                </Link>
              </li>
              <li>
                <Link to={"/produtos/marmore/default"}>
                  Mármore
                </Link>
              </li>
              <li>
                <Link to={"/produtos/silestone/default"}>
                  Silestone
                </Link>
              </li>
              <li>
                <Link to={"/produtos"}>
                  Todos
                </Link>
              </li>
            </ul>
          </div>
          <div className="navbar-footer">
            <h5>Sobre nós</h5>
            <ul>
              <li>
                <Link to={"/sobre"}>
                  Quem somos
                </Link>
              </li>
              <li>
                <Link to={"/produtos"}>
                  Produtos
                </Link>
              </li>
              <li>
                <Link to={"/orcamento"}>
                  Orçamento
                </Link>
              </li>
              <li>
                <Link to={"/blog"}>
                  Blog
                </Link>
              </li>
            </ul>
          </div>
          <div className="enterprise-social">
            <h5>Mantenha-se atualizado</h5>
            <div className="footer-social">
              <a className="social-brand" href={"https://www.facebook.com/ardosiamr"} target={"_blank"} rel={"noreferrer"}>
                <img src="./assets/font-awesome/icons/Facebook.svg" alt="Facebook Brand" />
              </a>
              <a className="social-brand" href={"https://www.instagram.com/ardosiamr/"} target={"_blank"} rel={"noreferrer"}>
                <img src="./assets/font-awesome/icons/instagram.png" height={"25px"} alt="Instagram Brand" />
              </a>
              <a className="social-brand" href={"https://wa.me/553195878210"} target={"_blank"} rel={"noreferrer"}>
                <img src="./assets/font-awesome/icons/whatsapp.png" height={"25px"} alt="Whatsapp  Brand" />
              </a>
            </div>
          </div>
        </div>
        <hr />
        <div className="copyright">
          <h5>2022 @ Todos os direitos reservados.</h5>
          <h5>Desenvolvido por @ <a className="primary-color" href={"https://www.cubedev.com.br/"} target={"_blank"} rel={"noreferrer"}> Cub³ Dev</a></h5>
          <div className="footer-social">
            <a className="social-brand" href={"https://wa.me/553195878210"} target={"_blank"} rel={"noreferrer"}>
              <img src="./assets/font-awesome/icons/whatsapp.png" height={"25px"} alt="Whatsapp Brand" />
            </a>
            <a className="social-brand" href={"https://www.instagram.com/cubedev_/"} target={"_blank"} rel={"noreferrer"}>
              <img src="./assets/font-awesome/icons/instagram.png" height={"25px"} alt="Instagram Brand" />
            </a>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer