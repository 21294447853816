import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { categorias } from '../data/Data';


const Navbar = () => {

    const [filterProds, setFilterProds] = useState([])
    const [search, setSearch] = useState("")

    let darkTheme = localStorage.getItem("theme") === "dark-theme" ? true : false

    const body = document.querySelector("body");

    if (localStorage.getItem("theme") === "dark-theme" && !body.classList.contains("dark-theme")) {
        body.classList.add("dark-theme")
    }

    const activeTheme = () => {
        if (body.classList.contains("dark-theme")) {
            localStorage.removeItem("theme");
            body.classList.remove("dark-theme");
            darkTheme = false
        } else {
            localStorage.setItem("theme", "dark-theme");
            body.classList.add("dark-theme");
            darkTheme = true
        }
        darkTheme ? setLogo(logoImg.logo02) : setLogo(logoImg.logo01)
        darkTheme ? setLupa(lupaIcon.icon02) : setLupa(lupaIcon.icon01)
        darkTheme ? setTheme(themeIcon.icon02) : setTheme(themeIcon.icon01)
    }

    const menuToggle = () => {
        let menuMobile = document.getElementById("menuToggle")
        let menuBtn = document.getElementById("menuBtn")

        if (menuBtn.classList.contains("active")) {
            menuMobile.classList.remove("active");
            menuBtn.classList.remove("active");
        } else {
            menuMobile.classList.add("active");
            menuBtn.classList.add("active");
        }
    }

    let logoImg = { logo01: "assets/img/logo.png", logo02: "assets/img/logoTheme.svg" }
    let lupaIcon = { icon01: "assets/font-awesome/icons/lupa.png", icon02: "assets/font-awesome/icons/lupaTheme.png" }
    let themeIcon = { icon01: "assets/font-awesome/icons/moon.png", icon02: "assets/font-awesome/icons/moonTheme.png" }

    const [logo, setLogo] = useState(darkTheme ? logoImg.logo02 : logoImg.logo01)
    const [lupa, setLupa] = useState(darkTheme ? lupaIcon.icon02 : lupaIcon.icon01)
    const [theme, setTheme] = useState(darkTheme ? themeIcon.icon02 : themeIcon.icon01)




    useEffect(() => {
        setFilterProds(categorias.filter(obj =>  obj.id.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ))
    }, [search])

    return (
        <>
            <div className="space-header"></div>
            <header className="header" id="header">
                <nav className="nav container">
                    <Link to={"/"} className="nav_logo">
                        <img src={logo} width="100" height="100" alt="Ardosia Mr" className="nav_logo-img" />
                    </Link>
                    <div className="nav_menu" id="nav-menu">
                        <ul className="nav_list">
                            <li className="nav_item">
                                <Link to={"/"} className="nav_link">Home</Link>
                            </li>
                            <li className="nav_item">
                                <Link to={"/sobre"} className="nav_link">Sobre nós</Link>
                            </li>
                            <li className="nav_item">
                                <Link to={"/produtos"} className="nav_link">Produtos</Link>
                            </li>
                            <li className="nav_item">
                                <Link to={"/orcamento"} className="nav_link">Orçamento</Link>
                            </li>
                            <li className="nav_item">
                                <Link to={"/blog"} className="nav_link">Blog</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="nav_btns">
                        <div className={["search-box", search.length? "active" : ""].join(" ")}>
                            <input
                                className="search-txt"
                                type="text"
                                placeholder="Pesquise..."
                                name="search"
                                onChange={(e) => { setSearch(e.target.value) }}
                                value={search}
                            />
                            <div className="search-btn">
                                <img id="pesquisa" src={lupa} width="24" height="24" alt="pesquisa" />
                            </div>
                            <ul className={["search-list", search.length? "active" : ""].join(" ")}>
                                {
                                    filterProds.map((obj) => {
                                        return(
                                            search.length?
                                            <Link className="search-link" key={obj.id} onClick={() => {setSearch("")}} to={`produtos/${obj.id}/default`}><li>{obj.nome}</li></Link>
                                            : ""
                                        )
                                    })
                                } 
                            </ul>
                        </div>
                        <div className="nav_btns">
                            <img className="change-theme" id="theme-button" onClick={activeTheme} src={theme} width="36" height="36" alt="Button Change Theme" />
                            <div id="menuBtn" className="menuBtn" onClick={menuToggle}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default Navbar