import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar"
import MenuToggle from "./components/MenuToggle"
import Footer from "./components/Footer"
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Home"
import Blog from "./pages/Blog"
import Orcamento from "./pages/Orcamento"
import Produtos from "./pages/Produtos"
import Produto from "./pages/Produto"
import Sobre from "./pages/Sobre"

const App = () => {

  return (
    <>
      <Navbar />
      <MenuToggle />
      <ScrollToTop>
        <Routes>
          <Route path='/' exact element={<Home/>} />
          <Route path='blog' element={<Blog/>}/>
          <Route path='orcamento' element={<Orcamento/>}/>
          <Route path='produtos' element={<Produtos/>}/>
          <Route path='produtos/:id/:collor' element={<Produto/>}/>
          <Route path='sobre' element={<Sobre/>}/>
        </Routes>
      </ScrollToTop>
      <Footer />
    </>
  );
}

export default App;
