

const Blog = () => {
  return (
    <>
      <section>
        <div className="container blog_page">
          <p className="blog_page_sub_title">Blog</p>
          <h1 className="blog_page_title">Página em construçao </h1>
        </div>
      </section>
    </>
  )
}

export default Blog