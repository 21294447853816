import { useState } from "react"
import { Link } from "react-router-dom"
import { categorias } from "../data/Data"
import MoreProducts from "../components/MoreProducts"
import CategoryCard from "../components/CategoryCard"

const Produtos = () => {

  const [categorys, setCategorys] = useState(categorias)    

  const renderCategory = categorys.map((obj) => {
    return (
      <CategoryCard key={obj.id} nome={obj.nome} img={obj.img} url={obj.id} qtdCollors={obj.cores.length} qtdProds={obj.produtos.length} />
    )
  })

  return (
    <>
      <section className="category container">
        <div className="info-page">
          <div className="page-title">
            <h1>Categorias</h1>
            <p>Verifique todas as nossas categorias para obter o produto que você precisa</p>
          </div>
          <div className="page-breadcrumb">
            <div className="breadcrumb">
              <Link to={"/"} className="breadcrumb-link">
                <img src="assets/font-awesome/icons/home.svg" alt="home icon" />
                <span>Home</span>
              </Link>
              <div className="breadcrumb-arrow">
                <img src="assets/font-awesome/icons/simple-arrow-right.svg" alt="arrow right" />
              </div>
              <span className="breadcrumb-state">Todas Categorias</span>
            </div>
          </div>
        </div>
        <div className="container-category">
          {renderCategory}
        </div>
        <MoreProducts />
      </section>
    </>
  )
}

export default Produtos