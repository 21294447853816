import { useState } from "react"
import { listPhotos } from "../data/Data"
import PhotosGallery from "../components/PhotosGallery"

const Sobre = () => {

  const [showGallery, setShowGallery] = useState(false)

  return (
    <>
      <section className={["photos-gallery", showGallery ? "active" : ""].join(" ")}>

        <PhotosGallery listPhotos={listPhotos}>
          <div className="picture" onClick={() => { setShowGallery(false) }}>
            <img src="assets/font-awesome/icons/close-menu.svg" alt="" />
          </div>
        </PhotosGallery>
      </section>
      <section className="container sobre-page">
        <section aria-label="sobre">
          <div className="sobre_baner">
            <div className="sobre_banner">
              <img src="assets/img/photos/baner sobre.png" alt="" />
            </div>
            <p className="sobre_sub_title">Sobre nós</p>
            <h1 className="sobre_title">Quem somos ?</h1>
            <p className="sobre_description">Tendo um líder que já esteve na empresa também como um colaborador, fez com que adquirisse uma visão integral das oportunidades e desafios do mercado. </p>
            <p className="sobre_description">Culminando no domínio dos processos e demandas necessárias para entregar um trabalho de excelência para seus atuais parceiros de mercado. </p>

            <p className="sobre_description">Concretizando assim histórias de sucesso com seus clientes, aliando uma localização privilegiada, entre Curvelo (centro de Minas Gerais), Belo Horizonte (capital mineira) e Confins onde situa-se o aeroporto internacional, o escoamento de produtos se tornou mais facilitado.</p>
            <p className="sobre_description">Além de benefícios geográficos, a Ardósia MR conta com uma expertise de mais de 30 anos no mercado, estando também sempre atualizada quando o assunto são tecnologias de ponta e maquinário industrial, sendo possível o atendimento de altas demandas com um alto padrão de eficiência.
            </p>
            <p className="sobre_description">Em virtude do fundador da empresa já ter tido a experiência de ser colaborador da antiga firma onde atualmente se encontra a Ardósia MR, ocorreu de que ele obtivesse conhecimento das reais necessidades de seus parceiros.
            </p>
            <p className="sobre_description">Elaborando um modelo de atendimento que acompanha o parceiro desde o projeto até a conclusão da obra, colocando a Ardósia MR como uma importante aliada contra todo tipo de imprevistos, pois na Ardósia MR o trabalho só termina com a satisfação do parceiro de mercado.
            </p>

          </div>
          <div className="nosso_proposito">
            <p className="sobre_sub_title">Missão e visão</p>
            <h1 className="sobre_title">Nosso Propósito</h1>
            <p className="sobre_description">Ter uma relação de parceria duradoura com nossos clientes e fazer com que eles se tornem cada vez melhores e mais bem vistos em seus segmentos é nosso norte.
              <br />
              Para toda decisão que tomamos internamente esse é o nosso principal fator de análise
            </p>
          </div>
        </section>
        <section className="sobre-timeline">
          <p className="sobre_sub_title">Sobre nós</p>
          <h1 className="sobre_title">História da Ardosia MR</h1>
          <div className="timeLine">
            <div className="line">
              <div className="entries">
                <div className="entry">
                  <div className="title">1992</div>
                  <div className="body">
                    <p>30 anos atrás, onde toda a historia  da MR se iniciou a partir de um jovem  chamado Mauro Rodrigues, com muito esforço, dedicação, e muita persistência, o jovem destemido que já era colaborador de uma empresa do mesmo ramo, trabalhava após o fim de expediente, em busca de melhorar  cada vez mais sua qualidade e seu desenvolvimento, árduo e duradouro para poder atender melhor os seus clientes.
                    </p>
                  </div>
                </div>
                <div className="entry">
                  <div className="title">2004</div>
                  <div className="body">
                    <p>Após 12 anos da abertura foi desenvolvida a indústria própria. A partir daí, contando com uma localização privilegiada há 80 km da capital (Belo Horizonte) e na posição central em relação as jazidas onde a Ardósia é extraída, a empresa melhorou significativamente a agilidade na entrega, com maior qualidade e menor preço!
                    </p>
                  </div>
                </div>
                <div className="entry">
                  <div className="title">2009</div>
                  <div className="body">
                    <p>O piso de ardósia era o principal produto trabalhado pela empresa, mas em 2009 foi onde a Ardósia MR aumentou a variedade de produtos trazendo revestimentos, pias, lavatórios, divisórias, soleiras... Mas a tradição de como a historia começou permanece na logo, com a dois dois pisos ilustrados junto com as iniciais do fundador.
                    </p>
                  </div>
                </div>
                <div className="entry">
                  <div className="title">2014</div>
                  <div className="body">
                    <p>Após 22 anos no mercado ocorreu um marco memorável, que foi a adesão de novas pedras no portfólio da empresa (Mármore, Granito, São Tomé, Miracema...) trazendo assim uma variedade maior para os clientes fidelizados e atendendo a demanda dos clientes potenciais, sempre visando qualidade e eficiência!
                    </p>
                  </div>
                </div>
                <div className="entry">
                  <div className="end"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sec-gallery">
          <div className="sobre">
            <p className="sobre_sub_title">Sobre nós</p>
            <h2 className="sobre_title">Galeria</h2>
          </div>
          <div className="galeria">
            <div className="img-galeria  h-2 w-2 cp" onClick={() => { setShowGallery(true) }}>
              <img src="assets/img/photos/foto1.png" alt="" />
            </div>
            <div className="img-galeria h-1 w-2 cp" onClick={() => { setShowGallery(true) }}>
              <img src="assets/img/photos/foto3.png" alt="" />
            </div>
            <div className="img-galeria h-1 w-2 cp" onClick={() => { setShowGallery(true) }}>
              <img src="assets/img/photos/foto4.png" alt="" />
            </div>
            <div className="img-galeria h-2 w-4 cp" onClick={() => { setShowGallery(true) }}>
              <img src="assets/img/photos/foto5.png" alt="" />
            </div>
            <div className="img-galeria h-1 w-2 cp" onClick={() => { setShowGallery(true) }}>
              <img src="assets/img/photos/foto2.png" alt="" />
            </div>
          </div>
          <div className="galeria-mobile">
            <div className="img-galeria h-3 w-1" onClick={() => { setShowGallery(true) }}>
              <img src="assets/img/photos/foto1.png" alt="" />
            </div>
            <div className="img-galeria h-1 w-1" onClick={() => { setShowGallery(true) }}>
              <img src="assets/img/photos/foto3.png" alt="" />
            </div>
            <div className="img-galeria h-1 w-1" onClick={() => { setShowGallery(true) }}>
              <img src="assets/img/photos/foto4.png" alt="" />
            </div>
            <div className="img-galeria h-1 w-1" onClick={() => { setShowGallery(true) }}>
              <img src="assets/img/photos/foto2.png" alt="" />
            </div>
            <div className="img-galeria h-2 w-1" onClick={() => { setShowGallery(true) }}>
              <img src="assets/img/photos/foto5.png" alt="" />
            </div>
          </div>
        </section>
        <section className="mt-3">
          <div className="center">
            <p className="sobre_sub_title">valores</p>
            <h2 className="sobre_title sobre_">Nossos Valores</h2>
          </div>
          <div className="sobre_valores">
            <div className="sobre_info_box">
              <h2 className="sobre_valores_title">Comprometimento</h2>
              <p className="sobre_valores_description">Suas metas e a qualidade da entrega são nossa prioridade.</p>
            </div>
            <div className="sobre_info_box">
              <h2 className="sobre_valores_title">Justiça</h2>
              <p className="sobre_valores_description">Buscamos sempre uma relação de saldo positivo para todos os lados da parceria.</p>
            </div>
            <div className="sobre_info_box">
              <h2 className="sobre_valores_title">Integridade</h2>
              <p className="sobre_valores_description"> para uma parceria de sucesso sempre seremos claros e éticos. </p>
            </div>
          </div>
        </section>
        <section className="mt-3">
          <div className="sobre center">
            <p className="sobre_sub_title">valores</p>
            <h2 className="sobre_title">Sustentabilidade</h2>
            <div id="sustentabilidade">
              <div className="sustentabilidade_container">
                <div className="sustentabilidade_services">
                  <div className="sustentabilidade_div">
                    <div className="block-img01"></div>
                    <div className="sustentabilidade_info">
                      <p className="sustentabilidade_text">Usina própria</p>
                      <p className="sustentabilidade_description">
                        Empresa integralmente abastecida energicamente por energia limpa, proveniente de usina fotovoltaica própria.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="sustentabilidade_services sustentabilidade_development">
                  <div className="sustentabilidade_div">
                    <div className="block-img02"></div>
                    <div className="sustentabilidade_info">
                      <p className="sustentabilidade_text">Sem resíduos
                      </p>
                      <p className="sustentabilidade_description">
                        Todo o subproduto dos processos são transformados e reaproveitados pela própria cadeia de produção
                      </p>
                    </div>
                  </div>
                </div>
                <div className="sustentabilidade_services sustentabilidade_development">
                  <div className="sustentabilidade_div">
                    <div className="block-img03"></div>
                    <div className="sustentabilidade_info">
                      <p className="sustentabilidade_text">Desperdício 0 de água
                      </p>
                      <p className="sustentabilidade_description">
                        Utilizamos um ciclo em que a água utilizada é 100% tratada e reaproveitada em outros processos internos.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  )
}

export default Sobre