import { Link } from "react-router-dom"

const CategoryCard = (props) => {
  return (
    <>
      <div className="category-card">
        <div className="category-image">
          <img src={props.img} alt="Imagem Produto" />
        </div>
        <div className="category-info">
          <h3>{props.nome}</h3>
          <ul>
            <li className="type-item">
              <span className="type-name">Cores</span>
              <span className="type-amount">{props.qtdCollors}</span>
            </li>
            <li className="type-item">
              <span className="type-name">Produtos</span>
              <span className="type-amount">{props.qtdProds}</span>
            </li>
          </ul>
          <div className="center">
            <Link to={`/produtos/${props.url}/default`} className="btn-category">Visualizar</Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default CategoryCard