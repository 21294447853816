import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getFirestore, addDoc, collection } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBrp0XebDB6im7f66D781SF826svc1qK_8",
  authDomain: "bd-ardosia-mr.firebaseapp.com",
  projectId: "bd-ardosia-mr",
  storageBucket: "bd-ardosia-mr.appspot.com",
  messagingSenderId: "653516690785",
  appId: "1:653516690785:web:10d31cb8ed89bbb3b5a120"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore();

const addRegister = (obj) => { return addDoc(collection(db, "registros"), obj) }

export const fb = {
  registro: {
    add: addRegister
  }
}