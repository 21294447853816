import { useState } from "react"
import { Link, useParams } from "react-router-dom"
import { categorias } from "../data/Data"
import MoreProducts from "../components/MoreProducts"

const Produto = () => {

  const { id, collor } = useParams()

  const [page, setPage] = useState(`${id}/${collor}`)

  const [produto, setProduto] = useState({})

  const [getData, setGetData] = useState(false)

  const [imgDefault, setImgDefault] = useState("")
  const [imgArray, setImgArray] = useState([])
  const [corProd, setCorProd] = useState("")
  const [listProds, SetListProds] = useState("")
  const [variacoesProd, setVariacoesProd] = useState([])

  if (page !== `${id}/${collor}`) {
    setPage(`${id}/${collor}`)
    setGetData(false)
  }

  const listProducts = (obj) => {
    if(!!obj.produtos){
      let qtd = obj.produtos.length
      let strProd = ""
      obj.produtos.forEach((prod, idx) => {
        strProd += prod + ", "
        if(idx + 1 === qtd){
          strProd += "entre outros."
        }
      })
      return (strProd)
    }
  }

  let obj = categorias.find((aux) => {
    return (aux.id === id)
  })

  if (!getData) {
    setGetData(true)
    setProduto(obj)
    setVariacoesProd(obj.cores)
    SetListProds(listProducts(obj))
    if (collor === "default") {
      setImgDefault(obj.cores[0].fotos[0])
      setImgArray(obj.cores[0].fotos)
      setCorProd(obj.cores[0].cor)
    } else {
      let objUrl = obj.cores.find((objUrl) => {
        return (objUrl.cor === collor)
      })
      setImgDefault(objUrl.fotos[0])
      setImgArray(objUrl.fotos)
      setCorProd(objUrl.cor)
    }
  }


  const selectOptions = variacoesProd.map((obj) => {
    return (
      <option value={obj.cor} key={obj.cor}>{obj.cor}</option>
    )
  })

  const changeData = (ev) => {
    const { name, value, src } = ev.target

    if (name === "cores") {
      let obj = variacoesProd.find((obj) => {
        return (obj.cor === value)
      })
      setImgDefault(obj.fotos[0])
      setCorProd(obj.cor)
      setImgArray(obj.fotos)
    } else if (name === "foto") {
      setImgDefault(src)
    }
  }

  return (
    <>
      <section className="produto container">
        <div className="info-page">
          <div className="page-breadcrumb">
            <div className="breadcrumb">
              <Link to={"/"} className="breadcrumb-link">
                <img src="assets/font-awesome/icons/home.svg" alt="home icon" />
                <span>Home</span>
              </Link>
              <div className="breadcrumb-arrow">
                <img src="assets/font-awesome/icons/simple-arrow-right.svg" alt="arrow right" />
              </div>
              <Link to={"/produtos"} className="breadcrumb-link">
                <img src="assets/font-awesome/icons/home.svg" alt="home icon" />
                <span>Todas Categorias</span>
              </Link>
              <div className="breadcrumb-arrow">
                <img src="assets/font-awesome/icons/simple-arrow-right.svg" alt="arrow right" />
              </div>
              <span className="breadcrumb-state">{produto.nome}</span>
            </div>
          </div>
        </div>
        <div className="product-container">
          <div className="product-photos">
            <h3 className="text-linear mobile">{produto.nome}</h3>
            <h2 className="mobile">{corProd}</h2>
            <div className="main-photo">
              <img src={imgDefault} alt="Foto Principal" />
            </div>
            <div className="slider-photos">
              <div className="item-slider">
                <img src={imgArray[0]} alt="Foto" name="foto" onClick={changeData} />
              </div>
              <div className="item-slider">
                <img src={imgArray[1]} alt="Foto" name="foto" onClick={changeData} />
              </div>
              <div className="item-slider">
                <img src={imgArray[2]} alt="Foto" name="foto" onClick={changeData} />
              </div>
            </div>
          </div>
          <div className="product-info">
            <div className="select-collor mobile">
              <label className="label-select">Cor</label>
              <select className="select" value={corProd} name="cores" onChange={changeData}>
                {selectOptions}
              </select>
            </div>
            <h3 className="text-linear desktop">{produto.nome}</h3>
            <h2 className="desktop">{corProd}</h2>
            <p>
              {produto.descricao}
            </p>
            <div className="info-block">
              <div className="block-product">
                <h5 className="text-linear">PRODUTOS</h5>
                <p>
                  {listProds}
                </p>
              </div>
              <div className="more-info">
                <h5 className="text-linear">SAIBA MAIS</h5>
                <p>
                  Para mais informações sobre cores e preços
                  entre em contato, solicite seu orçamento!
                </p>
              </div>
            </div>
            <div className="product-block-bottom">
              <div className="select-collor desktop">
                <label className="label-select">Cor</label>
                <select className="select" value={corProd} name="cores" onChange={changeData}>
                  {selectOptions}
                </select>
              </div>
              <Link to={"/orcamento"} className="botao fs09">SOLICITAR ORÇAMENTO</Link>
            </div>
          </div>
        </div>
        <MoreProducts />
      </section>
    </>
  )
}

export default Produto