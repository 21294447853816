export const categorias = [
  {
    id: "ardosia",
    nome: "Ardósia",
    img: "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Ardosia%2FArdosia%20cinza%2Fardosia%20principal.png?alt=media&token=8c54766d-f38a-4fa2-b3c6-f1d03f0a9701",
    descricao: `Processo de extração natural o que resulta em uma pedra única para sua casa, alta resistência mecânica, sofrendo poucas avarias em impactos que possam ocorrer. 

    Baixa absorção de líquido, o que garante com que a pedra não fique manchada e com infiltrações.
    `,
    produtos: [
      "Bancada",
      "Telha",
      "Divisoria",
      "Degrau",
      "Soleira",
      "Peitoril",
      "Moveis(Mesa e Banco)",
      "Revestimento",
      "Rodapé",
      "Piso Elevado",
      "Sinuca",
      "Pias e Lavatorios",
      "Vergas e Contravergas",

    ],
    cores: [
      {
        cor: "Cinza",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Ardosia%2FArdosia%20cinza%2Fardosia%20principal.png?alt=media&token=8c54766d-f38a-4fa2-b3c6-f1d03f0a9701",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Ardosia%2FArdosia%20cinza%2Fardosia_cinza01.png?alt=media&token=da47c3c2-77bf-40a6-b429-835ae2e5a2b7",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Ardosia%2FArdosia%20cinza%2Fardosia_cinza02.png?alt=media&token=77c07d66-e0dc-4f3d-abd8-7ec46c41f0a6"
        ]
      },
      {
        cor: "Ferrugem",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Ardosia%2FArdosia%20Ferrugem%2Fardosia_ferrugem02.png?alt=media&token=8e346c77-1cb9-4a59-a7e3-6bf0894b1a67",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Ardosia%2FArdosia%20Ferrugem%2Fardosia_ferrugem01.png?alt=media&token=f3b12264-1090-4f5f-8e1e-7b90b62eb16e",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Ardosia%2FArdosia%20Ferrugem%2Fardosia_ferrugem02.png?alt=media&token=8e346c77-1cb9-4a59-a7e3-6bf0894b1a67"
        ]
      },
      {
        cor: "Wales",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Ardosia%2FArdosia%20Wales%2Fardosia_wales02.png?alt=media&token=16af509c-c508-4ade-8b02-f2b21a8510e3",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Ardosia%2FArdosia%20Wales%2Fardosia_wales01.png?alt=media&token=846a517c-2aff-4f89-8c73-1afa5a26a8cf",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Ardosia%2FArdosia%20Wales%2Fardosia_wales02.png?alt=media&token=16af509c-c508-4ade-8b02-f2b21a8510e3"
        ]
      },
      {
        cor: "Verde",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Ardosia%2FArdosia%20verde%2Fardosia_verde02.png?alt=media&token=fadbd027-88e2-4fb2-8ac5-89f3da28b467",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Ardosia%2FArdosia%20verde%2Fardosia_verde01.png?alt=media&token=46ce5044-86a2-4c97-ba6b-352a0ecf411a",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Ardosia%2FArdosia%20verde%2Fardosia_verde02.png?alt=media&token=fadbd027-88e2-4fb2-8ac5-89f3da28b467"
        ]
      },
     
      {
        cor: "Preta",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Ardosia%2FArdosia%20Preta%2Fardosia_preta02.png?alt=media&token=78137552-90e8-4c67-bb67-ede1e19affa7",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Ardosia%2FArdosia%20Preta%2Fardosia_preta01.png?alt=media&token=d6491619-61f5-4be8-8f13-5f8915e879f9",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Ardosia%2FArdosia%20Preta%2Fardosia_preta02.png?alt=media&token=78137552-90e8-4c67-bb67-ede1e19affa7"
        ]
      }
    ]
  },


  {
    id: "granito",
    nome: "Granito",
    img: "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FAmarelo%20Brasil%2Fbancada-cozinha.jpg?alt=media&token=7e93f669-954a-4413-ad26-e55efdab1d7d",
    descricao: `Tipo de pedra altamente resistente ao calor, manchas,  impactos e principalmente a riscos. É uma pedra de fácil manutenção. É um tipo de pedra com alto grau de dureza, perfeito para revestimentos de pisos, paredes, mesas, balcões, fachadas, tampos de pia e mesas. Disponível somente polido.
    `,
    produtos: [
      "Soleira",
      "Peitoril",
      "Piso",
      "Degrau",
      "Revestimento",
      "Pias e Lavatorios",
      "Rodapé",
      "Verga e Contra verga",
      "Bancada",

    ],
    cores: [
      {
        cor: "Amarelo Brasil",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FAmarelo%20Brasil%2Fgranito_amarelo_brasil01.png?alt=media&token=8791442e-f141-47dc-8924-aa66fc457b63",
          
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FAmarelo%20Brasil%2Fgranito_amarelo_brasil02.png?alt=media&token=d203c8c5-618c-40e8-aced-02336d26ac89",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FAmarelo%20Brasil%2Fgranito_amarelo_brasil03.png?alt=media&token=18f3e32c-cd10-4a7d-a2fc-33a1842906f8",
        ]
      },
      {
        cor: "Amarelo Florença",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FAmarelo%20Florença%2Fgranito_amarelo_florenca01.png?alt=media&token=f8c1e051-0538-416a-83b8-50cbc967e42d",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FAmarelo%20Florença%2Fgranito_amarelo_florenca04.png?alt=media&token=2ca19f04-af74-4046-94ae-b10e3bb99dd8",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FAmarelo%20Florença%2Fgranito_amarelo_florenca03.png?alt=media&token=adca8dde-9f7f-4605-b0bb-31ec3a71bb64"
        ]
      },
      {
        cor: "Amarelo icarai",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FAmarelo%20Icarai%2Fgranito_amarelo_icarai001.png?alt=media&token=ef3e1c85-ecde-464d-99ee-7ebc8765e3e4",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FAmarelo%20Icarai%2Fgranito_amarelo_icarai03.png?alt=media&token=4d32a54f-3d21-469f-b814-85c917264064",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FAmarelo%20Icarai%2Fgranito_amarelo_icarai01.png?alt=media&token=89108cc3-52a4-4bdd-9444-bc2616e5514b"
        ]
      },
      {
        cor: "Amarelo Ornamental",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FAmarelo%20Ornamental%2Fgranito_amarelo_ornamental01.png?alt=media&token=34cf29eb-dc58-4b81-b5c6-28ada850e526",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FAmarelo%20Ornamental%2Fgranito_amarelo_ornamental04.png?alt=media&token=b228fca4-740c-44c7-a4bc-4fca9f1badf1",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FAmarelo%20Ornamental%2Fgranito_amarelo_ornamental03.png?alt=media&token=d58a2fd3-20ab-4904-8e13-09527140e09a"
        ]
      },
      {
        cor: "Amarelo Ouro Brasil",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FAmarelo%20Ouro%20Brasil%2Fgranito_amarelo_ouro_brasil01.png?alt=media&token=92dac3ec-e35a-4996-bb85-dd03c01ca90a",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FAmarelo%20Ouro%20Brasil%2Fgranito_amarelo_ouro_brasil04.png?alt=media&token=cbbf5410-d5b3-446a-8c4e-cc2314758d86",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FAmarelo%20Ouro%20Brasil%2Fgranito_amarelo_ouro_brasil03.png?alt=media&token=31a13c65-c076-49e4-837f-aeb0ca7fc1b9"
        ]
      },
      {
        cor: "Amarelo Santa Cecilia Light",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FAmarelo%20Santa%20Cecilia%20Light%2Fgranito_amarelo_santacecilia_light01.png?alt=media&token=c31af8b6-31ab-489a-bbbc-2a3528d95f03",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FAmarelo%20Santa%20Cecilia%20Light%2Fgranito_amarelo_santacecilia_light03.png?alt=media&token=68f6cc34-bb0b-44ab-8a9c-3c1f7c104fd4",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FAmarelo%20Santa%20Cecilia%20Light%2Fgranito_amarelo_santacecilia_light04.png?alt=media&token=65f8108e-25fe-4e00-b82f-68ba36f960da"
        ]
      },
      {
        cor: "Amarelo Santa Cecilia",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FAmarelo%20Santa%20Cecilia%2Fgranito_amarelo_santacecilia01.png?alt=media&token=a711e2c2-d4df-4400-bed9-ff7a160cf75b",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FAmarelo%20Santa%20Cecilia%2Fgranito_amarelo_santacecilia03.png?alt=media&token=9e7c2da3-5940-46ae-9212-9df0f54debca",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FAmarelo%20Santa%20Cecilia%2Fgranito_amarelo_santacecilia04.png?alt=media&token=6847c0a4-2a45-476c-bbed-b037a58d0c3a"
        ]
      },
      {
        cor: "Blue Light",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FBluelight%2Fgranito_bluenight01.png?alt=media&token=347a73c3-5150-4df1-b63d-22f565e1ccbf",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FBluelight%2Fgranito_bluenight02.png?alt=media&token=94304009-4401-4e1a-9072-483ef9e1beda",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FBluelight%2Fgranito_bluenight04.png?alt=media&token=ee1a4f7c-1949-4373-a2fd-4a805c26e481"
        ]
      },
      {
        cor: "Branco Ceara",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FBranco%20Ceara%2Fgranito_branco_ceara01.png?alt=media&token=7e81c4b4-6b7c-4c9e-8bd4-df6b52d30d9c",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FBranco%20Ceara%2Fgranito_branco_ceara04.png?alt=media&token=72d7982f-548d-472e-b824-736c7b201ead",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FBranco%20Ceara%2Fgranito_branco_ceara02.png?alt=media&token=c2f93d09-9f5b-4163-9b0e-3086c4cc8913"
        ]
      },
      {
        cor: "Branco Cristal",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FBranco%20Cristal%2Fgranito_branco_cristal02.png?alt=media&token=9fafdf5d-e91f-4f28-8fef-d9fb359fe3d0",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FBranco%20Cristal%2Fgranito_branco_cristal03.png?alt=media&token=41a4ff6a-d386-4f8b-a76d-9903401689b6",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FBranco%20Cristal%2Fgranito_branco_cristal01.png?alt=media&token=c95cb274-bbf5-4baa-b680-a5751e8b811e"
        ]
      },
      {
        cor: "Branco Dallas",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FBranco%20Dallas%2Fgranito_branco_dallas02.png?alt=media&token=c733eef3-9bca-4623-b11f-e64438ac46d4",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FBranco%20Dallas%2Fgranito_branco_dallas03.png?alt=media&token=c9d7fdd3-f7d2-457a-83c0-95e9618ed64f",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FBranco%20Dallas%2Fgranito_branco_dallas01.png.png?alt=media&token=c3cb5390-b571-4e33-855b-2c040a631861"
        ]
      },
      {
        cor: "Branco Esmeralda",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FBranco%20Esmeralda%2Fgranito%20_branco_esmeralda03.png?alt=media&token=1dbec847-a920-44c1-a165-94bcc8754d8a",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FBranco%20Esmeralda%2Fgranito%20_branco_esmeralda02.png?alt=media&token=d7d8f29b-7b40-4205-9bf9-6fc9db6f9fed",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FBranco%20Esmeralda%2Fgranito%20_branco_esmeralda01.png?alt=media&token=5786902c-fd3c-4a89-be81-89460f885aaf"
        ]
      },
      {
        cor: "Branco Itaunas",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FBranco%20Itaunas%2Fgranito_branco_itaunas03.png?alt=media&token=73620e6b-02ba-40d6-88c8-32f82c20618e",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FBranco%20Itaunas%2Fgranito_branco_itaunas02.png?alt=media&token=80b39270-13cb-445c-9d25-9aa33b9b08d4",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FBranco%20Itaunas%2Fgranito_branco_itaunas01.png?alt=media&token=f9d307c5-2a1b-4d0b-8e65-6f4e79cf84b1"
        ]
      },
      {
        cor: "Branco Marfim",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FBranco%20Marfim%2Fgranito_mafim04.png?alt=media&token=1122b586-b3e8-4172-a1bb-569d9553a180",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FBranco%20Marfim%2Fgranito_mafim02.png?alt=media&token=f04b55fd-3d7b-412f-ae4e-d6e21f0a6491",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FBranco%20Marfim%2Fgranito_mafim01.png?alt=media&token=e19da61c-b334-4ff9-9db0-acdecbd5c2ea"
        ]
      },
      {
        cor: "Branco Piracema",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FBranco%20Piracema%2Fgranito_branco_piracema03.png?alt=media&token=805313ee-66f5-4073-9225-7d9ced428789",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FBranco%20Piracema%2Fgranito_branco_piracema04.png?alt=media&token=1ff6cdd4-a38b-4c21-be60-bc3139792eec",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FBranco%20Piracema%2Fgranito_branco_piracema01.png?alt=media&token=6fb44fca-1316-46ac-bd9f-fbee5d99ede6"
        ]
      },
      {
        cor: "Café Imperial",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FCafe%20Imperial%2Fgranito_cafe_imperial04.png?alt=media&token=8278892c-e850-4c03-b09a-1dbad2575b5e",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FCafe%20Imperial%2Fgranito_cafe_imperial02.png?alt=media&token=f6d0780d-5477-41cd-b8aa-4d404744f419",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FCafe%20Imperial%2Fgranito_cafe_imperial01.png?alt=media&token=f7fb36f0-760c-466e-ab45-65789e7c1c2e"
        ]
      },
      {
        cor: "Cinza Andorinha",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FCinza%20Andorinha%2Fgranito_cinza_andorinha02.png?alt=media&token=6c08629e-62c2-48eb-b00b-5aee53a8f241",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FCinza%20Andorinha%2Fgranito_cinza_andorinha03.png?alt=media&token=3e8c858c-6691-4d8d-aee7-e2e92bee26e0",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FCinza%20Andorinha%2Fgranito_cinza_andorinha01.png?alt=media&token=29a4b39e-3df2-4dc5-9cbe-e02e10efaed0"
        ]
      },
      {
        cor: "Cinza Corumba",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FCinza%20Corumba%2Fgranito_cinza_corumba02.png?alt=media&token=069f3911-dcbe-44cf-b11f-42bedd6c1e80",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FCinza%20Corumba%2Fgranito_cinza_corumba04.png?alt=media&token=173fc02b-96f9-4bc3-9a93-f946ba5712f7",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FCinza%20Corumba%2Fgranito_cinza_corumba01.png?alt=media&token=12f0e477-78c7-40d5-9f82-e2b195ec1bc5"
        ]
      },
      {
        cor: "Cinza Ocre Itabira",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FCinza%20Itabira%2Fgranito_cinza_itabira02.png?alt=media&token=030bb1e1-1630-47f2-8b06-69eb6327c7d8",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FCinza%20Itabira%2Fgranito_cinza_itabira04.png?alt=media&token=c9d2dcb5-d4dc-4600-afd0-6ecc0fdcbda5",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FCinza%20Itabira%2Fgranito_cinza_itabira.png?alt=media&token=8fe941bb-fc43-4d8b-8811-dd5ae5041bc7"
        ]
      },
      {
        cor: "Dourado Carioca",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FDourado%20Carioca%2Fgranito_dourado_carioca04.png?alt=media&token=c63b416f-1ca0-476c-9a2d-1494df25721c",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FDourado%20Carioca%2Fgranito_dourado_carioca02.png?alt=media&token=bef18a5b-30db-4548-9e52-8a79b19b046d",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FDourado%20Carioca%2Fgranito_dourado_carioca01.png?alt=media&token=6c12a3d4-98f2-483f-8e3b-30959bec06c2"
        ]
      },
      {
        cor: "Marrom Absoluto",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FMarrom%20Absoluto%2Fgranito_marrom_absoluto02.png?alt=media&token=626a2429-26e6-4051-af53-27a65eded1d1",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FMarrom%20Absoluto%2Fgranito_marrom_absoluto04.png?alt=media&token=626576ba-c77e-4565-8af3-130a80f88468",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FMarrom%20Absoluto%2Fgranito_marrom_absoluto01.png?alt=media&token=38a64a5d-dedf-42bb-a92c-3b0094ff4d5e"
        ]
      },
      {
        cor: "Marrom Tabaco",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FMarrom%20Tabaco%2Fgranito_marrom_tabaco02.png?alt=media&token=7ce94812-5fd3-4afe-a995-dfa0c49c1cb6",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FMarrom%20Tabaco%2Fgranito_marrom_tabaco04.png?alt=media&token=a5d7a2f8-e822-4d33-a350-705503699f84",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FMarrom%20Tabaco%2Fgranito_marrom_tabaco01.png?alt=media&token=3f6769db-8966-4a3b-9325-3ec85e47e2f5"
        ]
      },
      {
        cor: "Preto Negresco",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FPreto%20Negresco%2Fgranito_preto_negresco03.png?alt=media&token=a4dd98a4-637a-4684-9d5e-6ad0a818d2e6",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FPreto%20Negresco%2Fgranito_preto_negresco04.png?alt=media&token=a2300ccc-5bd2-47df-a299-2af4129a9aa8",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FPreto%20Negresco%2Fgranito_preto_negresco01.png?alt=media&token=e3a5691c-f571-4ff3-9e04-72cca63dd457"
        ]
      },
      {
        cor: "Preto São Gabriel",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FPreto%20Sao%20Gabriel%2Fgranito_preto_saogabriel02.png?alt=media&token=58670e2b-04f3-48a6-a38c-fdf3dfce84ba",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FPreto%20Sao%20Gabriel%2Fgranito_preto_saogabriel03.png?alt=media&token=df25635c-1085-4812-936d-d845ddf38abe",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FPreto%20Sao%20Gabriel%2Fgranito_preto_saogabriel01.png?alt=media&token=340f17f5-0165-45be-85ac-e8dad7f8ec82"
        ]
      },
      {
        cor: "Preto Semi Absoluto",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FPreto%20Semi%20Absoluto%2Fgranito_preto_semiabsoluto04.png?alt=media&token=d004d013-ac8e-4d1f-bf92-79c34885cd13",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FPreto%20Semi%20Absoluto%2Fgranito_preto_semiabsoluto03.png?alt=media&token=495da2b1-29bf-449f-bc31-4b9aba0e2794",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FPreto%20Semi%20Absoluto%2Fgranito_preto_semiabsoluto01.png?alt=media&token=d9b98022-202f-4122-b736-c66940eea1a9"
        ]
      },
      {
        cor: "Preto Via Lactea",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FPreto%20Via-Lactea%2Fgranito_preto_vialactea02.png?alt=media&token=7963e2c4-5a33-4d2f-a40e-b3c88536043f",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FPreto%20Via-Lactea%2Fgranito_preto_vialactea04.png?alt=media&token=3f6490a1-78b3-4f52-945c-e26371ad3769",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FPreto%20Via-Lactea%2Fgranito_preto_vialactea01.png?alt=media&token=89a8fdd5-74b1-4421-9ddf-62662d1aa3c2"
        ]
      },
      {
        cor: "Red Verona",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FRed%20Verona%2Fgranito_red_verona04.png?alt=media&token=ef21bc77-a3bb-4097-bed6-aef16010f075",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FRed%20Verona%2Fgranito_red_verona02.png?alt=media&token=79abc664-4f63-46c0-a7a9-632f7d378586",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FRed%20Verona%2Fgranito_red_verona01.png?alt=media&token=2759a834-f6db-4637-8640-d56d1a270b35"
        ]
      },
      {
        cor: "Snowfall",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FSnowfall%2Fgranito_snowfall01.png?alt=media&token=69ccd587-42a7-472e-9252-826847135611",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FSnowfall%2Fgranito_snowfall04.png?alt=media&token=284ee4b5-7144-46c8-8bbb-411c68cdf0fd",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FSnowfall%2Fgranito_snowfall03.png?alt=media&token=077ecf5e-c805-4626-9273-a6e088ce7c07"
        ]
      },
      {
        cor: "Verde Bahia",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FVerde%20Bahia%2Fgranito_verde_bahia03.png?alt=media&token=a16160c5-8707-4aef-aceb-083657d73362",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FVerde%20Bahia%2Fgranito_verde_bahia04.png?alt=media&token=4ff8ed29-221e-48f5-8037-19d233caeec3",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FVerde%20Bahia%2Fgranito_verde_bahia01.png?alt=media&token=41037632-5e18-445c-b4b1-21f5351029ca"
        ]
      },
      {
        cor: "Verde Labrador",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FVerde%20Labrador%2Fgranito_verde_labrador02.png?alt=media&token=07e9a458-2364-416f-b984-00fb68337380",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FVerde%20Labrador%2Fgranito_verde_labrador04.png?alt=media&token=8085f2ac-3788-4091-827c-4c73e9f0cae6",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FVerde%20Labrador%2Fgranito_verde_labrador01.png?alt=media&token=7c8249be-8aa9-4349-9bd5-09ff8ce9bfd7"
        ]
      },
      {
        cor: "Verde Panorama",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FVerde%20Panorama%2Fgranito_verde_panorama02.png?alt=media&token=0ea17ca1-43d9-401e-8858-734fef2a36bc",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FVerde%20Panorama%2Fgranito_verde_panorama03.png?alt=media&token=7e3f66bb-a9fa-431a-89ee-c489d3020bcb",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FVerde%20Panorama%2Fgranito_verde_panorama01.png?alt=media&token=af3181ee-e4fd-44c7-a587-7d92809832dc"
        ]
      },
      {
        cor: "Verde Perola",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FVerde%20Perola%2Fgranito_verde_perola02.png?alt=media&token=42761452-be81-453c-a4fb-d2026bffa9d8",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FVerde%20Perola%2Fgranito_verde_perola03.png?alt=media&token=ef163230-cbc4-49ee-9d5e-17378ccbd143",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FVerde%20Perola%2Fgranito_verde_perola01.png?alt=media&token=2062b8c6-4b03-4731-9b78-641733d0e0e3"
        ]
      },
      {
        cor: "Verde Ubatuba",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FVerde%20Ubatuba%2Fgranito_verde_ubatuba02.png?alt=media&token=ce5b47eb-dc78-4804-a74c-f352fda8162e",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FVerde%20Ubatuba%2Fgranito_verde_ubatuba03.png?alt=media&token=3cfd98e2-38e0-4722-8b7c-6eea497abbc1",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FVerde%20Ubatuba%2Fgranito_verde_ubatuba01.png?alt=media&token=c41f373e-775e-47fa-961e-94d905f6c3f4"
        ]
      },
      {
        cor: "Vermelho Brasília",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FVermelho%20Brasilia%2Fgranito_vermelho_brasilia04.png?alt=media&token=b0a2f83f-9a3f-4940-9b43-82b5dff9c4f3",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FVermelho%20Brasilia%2Fgranito_vermelho_brasilia03.png?alt=media&token=f2c6b619-2827-46c7-874c-78cba47b6cfc",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FVermelho%20Brasilia%2Fgranito_vermelho_brasilia01.png?alt=media&token=18a2c382-8d57-4179-880f-980610687033"
        ]
      },
    ]
  },


  {
    id: "portuguesa",
    nome: "Pedra Portuguesa",
    img: "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20Portuguesa%2Fvermelha%2Fpedra_portuguesa_vermelha03.png?alt=media&token=35049761-4750-4094-964e-d7f387f7a6ee",
    descricao: `Geralmente usada na pavimentação de passeios e formação de mosaicos. Também conhecida como “calçada portuguesa” ou “mosaico português” ou “calçada mosaico”. Esta possui alta flexibilidade de montagem e foi a pedra utilizada na construção do famoso “calçadão” do Rio de Janeiro.`,
    produtos: [
      "Piso",
      "Revestimento"

    ],
    cores: [
      {
        cor: "Amarela",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20Portuguesa%2FAmarela%2Fpedra_portuguesa_amarela03.png?alt=media&token=75c7ef5c-8df8-4d87-8f99-fd00f33c2868",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20Portuguesa%2FAmarela%2Fpedra_portuguesa_amarela02.png?alt=media&token=d8c7282c-c623-4a85-bfdf-99feadb0a16c",
          
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20Portuguesa%2FAmarela%2Fpedra_portuguesa_amarela01.png?alt=media&token=c16d5823-8929-425f-a7c5-8d78d4decc6d"
        ]
      },
      {
        cor: "Branco Gelo",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20Portuguesa%2FBranco%20Gelo%2Fpedra_portuguesa_branco_gelo03.png?alt=media&token=1454f798-4600-482b-90c2-6eb5d98705e3",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20Portuguesa%2FBranco%20Gelo%2Fpedra_portuguesa_branco_gelo02.png?alt=media&token=a0cebdc7-8ded-427d-9e5e-a5a246d0241c",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20Portuguesa%2FBranco%20Gelo%2Fpedra_portuguesa_branco_gelo.png?alt=media&token=9abb2220-b883-45dc-be7b-9093b2973a5c"
        ]
      },
      {
        cor: "Branco Neve",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20Portuguesa%2FBranco%20Neve%2Fpedra_portuguesa_branca_neve02.png?alt=media&token=e5794f36-f586-41fd-9dd6-14cb79d7f1c1",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20Portuguesa%2FBranco%20Neve%2Fpedra_portuguesa_branca_neve03.png?alt=media&token=199edfd8-dbd2-411b-8827-e897394a466f",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20Portuguesa%2FBranco%20Neve%2Fpedra_portuguesa_branca_neve01.png?alt=media&token=687193c5-730b-41ac-bc0f-1e60dcbad046"
        ]
      },
      {
        cor: "Cinza",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20Portuguesa%2FCinza%2Fpedra_portuguesa_cinza03.png?alt=media&token=c126b02c-2180-4ef8-8ebe-7eed7906ec2d",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20Portuguesa%2FCinza%2Fpedra_portuguesa_cinza02.png?alt=media&token=9dcf5f4e-74fa-4bfa-86b0-5a60e49e4dc8",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20Portuguesa%2FCinza%2Fpedra_portuguesa_cinza01.png?alt=media&token=d4c2ada4-0a11-45bc-bfd3-070940491549"
        ]
      },
      {
        cor: "Creme",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20Portuguesa%2FCreme%2Fpedra_portuguesa_creme03.png?alt=media&token=14ae4e0c-0c76-4632-a097-91950e72e0af",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20Portuguesa%2FCreme%2Fpedra_portuguesa_creme02.png?alt=media&token=9834274b-c794-4bdc-8985-03e3fe555323",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20Portuguesa%2FCreme%2Fpedra_portuguesa_creme01.png?alt=media&token=71da5174-5730-4505-9e82-5bec360bf105"
        ]
      },
      {
        cor: "Multicor",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20Portuguesa%2FMulticor%2Fpedra_portuguesa_multicor01.png?alt=media&token=993eb21c-e0cb-4707-b4fa-13b1143b513b",
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20Portuguesa%2FMulticor%2Fpedra_portuguesa_multicor01.png?alt=media&token=993eb21c-e0cb-4707-b4fa-13b1143b513b",
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20Portuguesa%2FMulticor%2Fpedra_portuguesa_multicor01.png?alt=media&token=993eb21c-e0cb-4707-b4fa-13b1143b513b",

        ]
      }
      ,
      {
        cor: "Preto",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20Portuguesa%2FPreta%2Fpedra_portuguesa_preta03.png?alt=media&token=94b3de9c-a2f2-48f7-800e-4e2e66e877a8",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20Portuguesa%2FPreta%2Fpedra_portuguesa_preta02.png?alt=media&token=3b775aa4-d4bc-45b6-a421-a27e01dfd68a",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20Portuguesa%2FPreta%2Fpedra_portuguesa_preta01.png?alt=media&token=fb186736-5585-4c2d-a74d-2ca2c0c0c222"
        ]
      },
      {
        cor: "Vermelho",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20Portuguesa%2Fvermelha%2Fpedra_portuguesa_vermelha02.png?alt=media&token=643a53c5-7b72-4543-89b9-55129737ebed",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20Portuguesa%2Fvermelha%2Fpedra_portuguesa_vermelha03.png?alt=media&token=35049761-4750-4094-964e-d7f387f7a6ee",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20Portuguesa%2Fvermelha%2Fpedra_portuguesa_vermelha01.png?alt=media&token=3dd49714-e1f9-40e3-8cf0-3efbf46f660f"
        ]
      }
    ]
  },


  {
    id: "sao tome",
    nome: "Pedra São Tomé",
    img: "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20São%20Tomé%2FAmarela%2Fpedra_sao_tome_amarela03.png?alt=media&token=6dd3fcc3-10c3-4049-b9a1-b88de69c03aa",
    descricao: `Ideal para àreas externas próximas a piscinas ou varandas abertas pelo seu alto nivel de porosidade e resistência térmica e aspecto muito parecido com antiderrapantes. Uma das principais vantagens desse modelo é a sua resistência, que a torna perfeita para calçadas, fachadas e àreas da casa que sofrem com ações do tempo, como muito sol e chuva.

    `,
    produtos: [
      "Piso",
      "Revestimento"

    ],
    cores: [
      {
        cor: "Amarela",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20São%20Tomé%2FAmarela%2Fpedra_sao_tome_amarela03.png?alt=media&token=6dd3fcc3-10c3-4049-b9a1-b88de69c03aa",
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20São%20Tomé%2FAmarela%2Fpedra_sao_tome_amarela02.png?alt=media&token=9e699edb-8594-4955-bcc0-ea1b1d64bd57",
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20São%20Tomé%2FAmarela%2Fpedra_sao_tome_amarela01.png?alt=media&token=cdb080cf-071a-41ba-8798-a47b23406cd6"
        ]
      },
      {
        cor: "Branco",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20São%20Tomé%2FBranco%2Fpedra_sao_tome_branca.png?alt=media&token=ba4211a7-a531-41b9-a49f-f3a2c56d2d80",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20São%20Tomé%2FBranco%2Fpedra_sao_tome_branca02.png?alt=media&token=d9edaa17-6b3d-4876-83ea-3dfc048994da",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20São%20Tomé%2FBranco%2Fpedra_sao_tome_branca03.png?alt=media&token=02a3e23f-915c-4dc2-8156-fd6ab77585b3"
        ]
      },
      {
        cor: "Mesclada",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20São%20Tomé%2FMesclada%2Fpedra_sao_tome_mesclada02.png?alt=media&token=114bafec-a3a5-4953-bfcf-bd50058dc15c",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20São%20Tomé%2FMesclada%2Fpedra_sao_tome_mesclada03.png?alt=media&token=86d8ce27-a3c3-4a37-80d0-40e59a278120",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20São%20Tomé%2FMesclada%2Fpedra_sao_tome_mesclada01.png?alt=media&token=0a9e8ce3-eda5-4759-968f-7c457e0f51cc"
        ]
      },
      {
        cor: "Rosa",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20São%20Tomé%2FRosa%2Fpedra_sao_tome_rosa03.png?alt=media&token=809e8297-6154-44d5-9328-9a7b6ebae55d",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20São%20Tomé%2FRosa%2Fpedra_sao_tome_rosa02.png?alt=media&token=24648232-a5b3-4e7a-975d-1bbdc240b718",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Pedra%20São%20Tomé%2FRosa%2Fpedra_sao_tome_rosa.png?alt=media&token=79514826-5030-476a-b092-24a03de12ee5"
        ]
      }
    ]
  },


  {
    id: "miracema",
    nome: "Miracema",
    img: "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Miracema%2Fmiracema%201.png?alt=media&token=9c9d0c4b-77c0-4729-9495-9a707ae4a887",
    descricao: `Uma das alternativas mais requisitadas por profissionais da àrea de construção civil, pois combina tanto nas àreas externas quanto nas internas. A miracema pode ser encontrada em diferentes tonalidades e também se destaca pela sua durabilidade e textura antiderrapante, é comumente usada em àreas externas como calçadas e muros, mas vem ganhando cada vez mais espaço na composição de interiores pela sua variedade de cores e tonalidades.`,
    produtos: [
      "Piso",
      "Revestimento"

    ],
    cores: [
      {
        cor: "Original",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Miracema%2Fmiracema%201.png?alt=media&token=9c9d0c4b-77c0-4729-9495-9a707ae4a887",
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Miracema%2Firacema-02.png?alt=media&token=edd43144-c8d1-44aa-906b-7fd199665033",
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Miracema%2FMiracema-03.png?alt=media&token=2d8b1d83-e760-40f6-a842-37b134f18462"
        ]
      }
    ]
  },


  {
    id: "marmore",
    nome: "Mármore",
    img: "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Marmore%2Fmarmoreprincipal.png?alt=media&token=09e59efe-e269-4677-ac75-e6beab00886c",
    descricao: `Este modelo de pedra é mais poroso, macio e requer mais cuidados se comparado ao granito. O mármore é um tipo de pedra mais caro e luxuoso. Por ser mais poroso recomenda-se o selamento do mesmo afim de proteger e prolongar sua vida.
    `,
    produtos: [
      "Peitoril",
      "Revestimento",
      "Soleira",
      "Bancada",

    ],
    cores: [
      {
        cor: "Blue shadow",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Marmore%2FBlue%20Shadow%2Fmarmore_blue_shadow01.png?alt=media&token=60669224-94c6-4efa-93e4-67cafb25b98e",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Marmore%2FBlue%20Shadow%2Fmarmore_blue_shadow01.png?alt=media&token=60669224-94c6-4efa-93e4-67cafb25b98e",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Marmore%2FBlue%20Shadow%2Fmarmore_blue_shadow01.png?alt=media&token=60669224-94c6-4efa-93e4-67cafb25b98e"
        ]
      },
      {
        cor: "Branco Vitoria",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Marmore%2FBranco%20Vitoria%2Fmarmore_branco_vitoria01.png?alt=media&token=ba56b36c-1a30-4ee2-a8fa-0b35674c8b1e",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Marmore%2FBranco%20Vitoria%2Fmarmore_branco_vitoria01.png?alt=media&token=ba56b36c-1a30-4ee2-a8fa-0b35674c8b1e",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Marmore%2FBranco%20Vitoria%2Fmarmore_branco_vitoria01.png?alt=media&token=ba56b36c-1a30-4ee2-a8fa-0b35674c8b1e"
        ]
      },
    ]
  },


  {
    id: "silestone",
    nome: "Silestone",
    img: "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Silestone%2Fsilestone02.png?alt=media&token=bbec4247-d683-4b92-b67b-0ee1a912f3c0",
    descricao: `Inovação em revestimentos e bancadas, no Brasil seu uso vem aumentando ano após ano de forma significativa como uma das alternativas de substituição do Mármore. O silestone é fabricado com alta tecnologia, o que resulta numa pedra industrializada. Seus pontos fortes são a aparência, praticidade, inúmeras possibilidades decorativas e principalmente pela sua resistência e baixa porosidade. `,
    produtos: [
      "Peitoril",
      "Revestimento",
      "Soleira",
      "Bancada",
      "Pia e Lavatório",


    ],
    cores: [
      {
        cor: "Original",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Silestone%2Fsilestone02.png?alt=media&token=bbec4247-d683-4b92-b67b-0ee1a912f3c0",
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Silestone%2Fsilestone03.png?alt=media&token=eee149d6-d88f-4f29-9ee4-1dac052f875c",
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Silestone%2Fsilestone01.png?alt=media&token=fbbe8e87-a8e5-4c0c-b3b1-786d83707a56"
        ]
      }
    ]
  },


  {
    id: "quartzo",
    nome: "Quartzo",
    img: "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Quartzo%2Fquartzo02.png?alt=media&token=1dbee1b9-bdf6-4870-9ffa-ae835e1766ee",
    descricao: `Bastante cobiçado pelas suas cores, o quartzo é uma ótima opção para bancadas de cozinha e mesas pela sua superfície lisa e uniforme e principalmente pela sua resistência. É uma pedra industrializada que permite uma maior variedadew de cores e estilo, resistente e manchas e bactérias.`,
    produtos: [
      "Peitoril",
      "Revestimento",
      "Soleira",
      "Bancada",
      "Pia e Lavatório",

    ],
    cores: [
      {
        cor: "Original",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Quartzo%2Fquartzo01.png?alt=media&token=ad7f7452-f8fd-4e7e-8557-c3103a3697aa",
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Quartzo%2Fquartzo02.png?alt=media&token=1dbee1b9-bdf6-4870-9ffa-ae835e1766ee",
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Quartzo%2Fquartzo03.png?alt=media&token=ffb4abca-b5ec-42e9-9623-c0dbe4741690"
        ]
      }
    ]
  },


  {
    id: "quartzito",
    nome: "Quartzito",
    img: "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Quartzito%2Fquartzito01.png?alt=media&token=24440af8-a34f-4e37-b5fc-5506dbe066ee",

    descricao: `Composta quase inteiramente de quartzo, geralmente é utilizada como rocha de revestimento decorativo externo e interno. Possui excelente resistência e baixa porosidade, bonita como o mármore e forte como granito, este se chama quartzito.`,
    produtos: [
      "Peitoril",
      "Revestimento",
      "Soleira",
      "Bancada",
      "Pia e Lavatório",

    ],
    cores: [
      {
        cor: "Original",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Quartzito%2Fquartzito01.png?alt=media&token=24440af8-a34f-4e37-b5fc-5506dbe066ee",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Quartzito%2Fquartzito02.png?alt=media&token=89890995-30a0-4b14-b22a-ca150ec811b5",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Quartzito%2Fquartzito03.png?alt=media&token=9a851c99-c978-467a-86e9-92cc45959f36"
        ]
      }
    ]
  },


  {
    id: "travertino",
    nome: "Travertino",
    img: "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Travertino%2Ftravertino02.png?alt=media&token=2177c099-e5e9-4a30-a669-8dde629c3dbf",
    descricao: `Uma opção nobre, elegante e atemporal, de fácil aplicação, boa durabilidade e qualidades estéticas. É usado em estado natural ou polido, aplicado em interiores, exteriores e decorações. Agrega sofisticação e valor aos projetos arquitetônicos.
    `,
    produtos: [
      "Peitoril",
      "Revestimento",
      "Soleira",
      "Bancada",
      "Pia e Lavatório",
      "Piso",
      "Rodapé",
      "Degrau",
      "Verga e Contraverga"

    ],
    cores: [
      {
        cor: "Original",
        fotos: [
          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Travertino%2Ftravertino02.png?alt=media&token=2177c099-e5e9-4a30-a669-8dde629c3dbf",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Travertino%2Ftravertino01.png?alt=media&token=8f9d78ca-3a9c-41cd-867b-eb94536ac6a8",

          "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Travertino%2Ftravertino03.png?alt=media&token=cfaf11b2-5c54-4e22-a3db-ba5176068afe"
        ]
      }
    ]
  }
]


export const maisVendidos = [
  {
    id: "ardosia",
    nome: "Ardósia",
    cor: "Cinza",
    img: "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Produtos%20mais%20vendidos%2Fardosia%20principal%201.png?alt=media&token=4de59626-d31b-48d5-9693-d67e6f95a892",
  },
  {
    id: "granito",
    nome: "Granito",
    cor: "Branco Cristal",
    img: "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Produtos%20mais%20vendidos%2Fgranito_branco_cristal03%201.png?alt=media&token=d4f55e07-c8a7-4b88-8fc7-39e7e2f135b9",
  },
  {
    id: "marmore",
    nome: "Marmore",
    cor: "Branco Vitoria",
    img: "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Produtos%20mais%20vendidos%2Fmarmoreprincipal%201.png?alt=media&token=43a51925-8116-46ab-ab9d-e92d458f35b1",
  },
  {
    id: "sao tome",
    nome: "Sao tomé",
    cor: "Branco",
    img: "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Produtos%20mais%20vendidos%2Fpedra_sao_tome_branca03%201.png?alt=media&token=2071c7b3-afcd-471d-8178-3d6a4f672899",
  },
  ,
  {
    id: "granito",
    nome: "Granito",
    cor: "Cinza Andorinha",
    img: "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Produtos%20mais%20vendidos%2Fgranito_cinza_andorinha03%201.png?alt=media&token=b122aaa2-a900-4a2e-b58a-dc5446d6e836",
  },
  ,
  {
    id: "granito",
    nome: "Granito",
    cor: "Preto São Gabriel",
    img: "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Produtos%20mais%20vendidos%2Fgranito_preto_saogabriel03%201.png?alt=media&token=73d3f708-736d-4adf-900b-5c2fe2d9547b",
  },
  ,
  {
    id: "granito",
    nome: "Granito",
    cor: "Amarelo Ornamental",
    img: "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Produtos%20mais%20vendidos%2Fgranito_amarelo_ornamental04%201.png?alt=media&token=a85f40cd-bd65-492a-861a-3bbbd0e9c2fe",
  },
  ,
  {
    id: "granito",
    nome: "Granito",
    cor: "Cinza Ocre Itabira",
    img: "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/Granito%2FCinza%20Ocre%20Itabira%2Fgranito_cinza_ocre_itabira01.png?alt=media&token=a1d763e2-b92a-4db9-b5af-ac94abd340a7",
  }
]


export const homeProducts = {
  prods01: [
    {
      id: "ardosia",
      nome: "Ardósia",
      cor: "Cinza",
      img: "assets/img/photos/quadradas/ardosia principal.png",
    },
    {
      id: "ardosia",
      nome: "Ardósia",
      cor: "Preta",
      img: "assets/img/photos/quadradas/ardosia_preta02.png",
    },
    {
      id: "ardosia",
      nome: "Ardósia",
      cor: "Ferrugem",
      img: "assets/img/photos/quadradas/ardosia_ferrugem02.png",
    },
    {
      id: "ardosia",
      nome: "Ardósia",
      cor: "Verde",
      img: "assets/img/photos/quadradas/ardosia_verde02.png",
    },
  ],
  prods02: [
    {
      id: "granito",
      nome: "Granito",
      cor: "Preto São Gabriel",
      img: "assets/img/photos/quadradas/granito preto.png",
    },
    {
      id: "granito",
      nome: "Granito",
      cor: "Branco Cristal",
      img: "assets/img/photos/quadradas/granito_branco_cristal03 1.png",
    },
    {
      id: "granito",
      nome: "Granito",
      cor: "Cinza Ocre Itabira",
      img: "assets/img/photos/quadradas/granito_cinza_ocre_itabira01.png",
    },
    {
      id: "granito",
      nome: "Granito",
      cor: "Vermelho Brasília",
      img: "assets/img/photos/quadradas/granito_vermelho_brasilia04.png",
    },
  ],
  prods03: [
    {
      id: "sao tome",
      nome: "São tomé",
      cor: "Amarela",
      img: "assets/img/photos/quadradas/saotome 3.png",
    },
    {
      id: "sao tome",
      nome: "São tomé",
      cor: "Branco",
      img: "assets/img/photos/quadradas/saotome1.png",
    }, {
      id: "sao tome",
      nome: "São tomé",
      cor: "Mesclada",
      img: "assets/img/photos/quadradas/saotome 2.png",
    }, {
      id: "sao tome",
      nome: "São tomé",
      cor: "Rosa",
      img: "assets/img/photos/quadradas/pedra_sao_tome_rosa03.png",
    },
  ],
  prods04: [
    {
      id: "portuguesa",
      nome: "Portuguesa",
      cor: "Branco Neve",
      img: "assets/img/photos/quadradas/portuguesa_branca.png",
    },
    {
      id: "portuguesa",
      nome: "Portuguesa",
      cor: "Preto",
      img: "assets/img/photos/quadradas/pedra_portuguesa_preta03.png",
    },
    {
      id: "portuguesa",
      nome: "Portuguesa",
      cor: "Amarela",
      img: "assets/img/photos/quadradas/pedra_portuguesa_amarela03.png",
    },{
      id: "portuguesa",
      nome: "Portuguesa",
      cor: "Vermelho",
      img: "assets/img/photos/quadradas/pedra_portuguesa_vermelha03.png",
    },
  ],
}

export const depositions = [
  {
    img:"/assets/img/photos/profile2.png",
    nome: "Gabriel M.",
    text: `“Desde que comecei a trabalhar com a Ardósia MR tive a tranquilidade de oferecer os melhores prazos do mercado, destacando-me perante os meus concorrentes, só tenho a agradecer.”`
  },
  {
    img:"/assets/img/photos/profile2.png",
    nome: "Luciana A",
    text: `“Quando colegas de segmento me indicaram a Ardósia MR confesso que achava a propaganda boa demais para ser verdade, mas vi que é tudo isso que me falavam mais um pouco, eles realmente me tratam como parceira de mercado e isso é incrivel”`
  },
  {
    img:"/assets/img/photos/profile2.png",
    nome: "Stefano G",
    text: `“Cada vez consigo pegar projetos maiores, tenho uma vida antes e uma outra vida após conhecer a Ardósia MR”`
  },
  {
    img:"/assets/img/photos/profile2.png",
    nome: "Amadeu F.",
    text: `“Eles me tratam como se fosse um grande parceiro mesmo sendo um cliente “pequeno”, até hoje isso me intriga”`
  },
  {
    img:"/assets/img/photos/profile2.png",
    nome: "Ana Carolina",
    text: `“Graças a Ardósia MR posso deixar minha criatividade alçar vôos que sempre quis, mas por experiências ruins com outras empresas já achava algo distante”`
  },
  {
    img:"/assets/img/photos/profile2.png",
    nome: "Bernardo S.",
    text: `“Mesmo estando em outro estado eles conseguem me entregar mais rapidamente que empresas aqui de São Paulo”`
  },
]



export const listPhotos = [
  "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/galery%2Fimg%203.JPG?alt=media&token=889d8b05-eb5b-4e5d-8833-84e279f716c1",

  "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/galery%2Fimg%204.JPG?alt=media&token=666d23a8-f641-4075-aa46-3662ab218215",

  "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/galery%2Fimg1.JPG?alt=media&token=c9b45d42-c6aa-45fd-b7dd-518698e5bb41",

  "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/galery%2Fimg9.JPG?alt=media&token=d29527b4-e3bf-441f-a80b-b741c3069747",

  "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/galery%2Fimg%203.JPG?alt=media&token=889d8b05-eb5b-4e5d-8833-84e279f716c1",

  "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/galery%2Fimg10.JPG?alt=media&token=7a2bde7a-1eac-4632-b46f-f360264141e5",

  "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/galery%2Fimg11.JPG?alt=media&token=bcf33d83-9e16-44fa-af3e-ec5b205cc7bc",

  "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/galery%2Fimg12.JPG?alt=media&token=83e05c1d-2a3e-4538-8f85-d32f92096721",

  "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/galery%2Fimg2.JPG?alt=media&token=3319583e-f7fb-4ae4-af06-484bf081d9ef",

  "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/galery%2Fimg5.JPG?alt=media&token=512c8829-5ee0-47b2-9499-28f49fa19306",

  "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/galery%2Fimg6.JPG?alt=media&token=bc7fade7-2610-4e28-a266-9f09cd60deda",

  "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/galery%2Fimg7.JPG?alt=media&token=8112961d-fe22-43aa-87e5-80d425974d75",

  "https://firebasestorage.googleapis.com/v0/b/bd-ardosia-mr.appspot.com/o/galery%2Fimg8.JPG?alt=media&token=dd4eb4e9-007f-4d37-a350-c01e69b42e8f",

  
]